export const PGP_PUBLIC_KEY =
`-----BEGIN PGP PUBLIC KEY BLOCK-----
Comment: signer-pgp-demo

mQINBFvs3IwBEADmm9uRgNrQ2DGKzEmkmgRHOcNR5yZMowz2nb1E0PXzTkvTLcBi
NPXPORJ4XT0egB7TZlo21Sho45jEIfL6guXpco3CxO09CHipyuguOAqfCNRdPuzd
mkOhcf3j+Js7T8rTvNgJ9i/xmZDiY9B31c0CLTD250beU+TwaNG0Oy7dO5jVz7NL
s0FTZelTmq70PqTMTBtdwZtTFvQ8Msm/AOVmCeCstYCsNG8pYdO6bvpbV3HjUKta
l4gJ1Hn4rqEIDqE8BwlX2jvK5Pwvb1mvEUYTidG1bqIQNxIh48e3m8GSaXoJ0edJ
/kNLnzDLZXmqN+YMvyK3sqsoLpGRoRgTIwOwTQCqr4tPVN2MMBR/68moqtPcoGOL
EVHLRXVGjpn7jWQAHaEKYdNPHaEYAqd5pFZvtQD3rb0eCogEUtYP/98OPvMcrS/m
2qhp04xA88lf0/ECM0k33Ev+RrPVWylbBXe0HhKRFwilsouoBbKgVW25QzdPtrLn
JAopFbbYpWKL38Ep13edWE+8Z95b/9uzf1P3lh+AdjHMAQdsNJr4uVM+9GcK4FEm
Ksx/3bFzjMGhsREEoi8DDSR9Jb3QQlKX3bqpfzajeTZIjiyRbc7MWQTUeBXjWR4I
wXX4Xraok+Iiu2Kj+K/XVQqkqEcNt9whmyZWaaiUXl0H9+e9jSU7Wu9RYQARAQAB
tCNDQVNQSUFOIE1TUyA8bXNzLWRlbW9AY2FzcGlhbi50ZWNoPokCTgQTAQgAOBYh
BIR37mvPHsr9PYf3m0rYYFqYim79BQJb7NyMAhsDBQsJCAcCBhUKCQgLAgQWAgMB
Ah4BAheAAAoJEErYYFqYim7940EP/j+GACHi/Rjxgr1bzaIFx9v2c55JRYQ0VUhz
fxiafs5bxn4auDZFbN5ssoJhbo1e3WtBYK9k8GvQZcxCC1O0vAAXbxVdezSwOVoT
g1z97s0h7/VIz1frsHRE7xm+iQUm7IzYtHyqzlglwwyB8D00CvoF1gpBAbxVevvg
ityZdHGYb1XwjIT+m+7/C3hcsqftea86l/CwhUNqWBQ03XDkDk4QkmRbqeJ2wNZG
ZOvCF8mO+gwyed4PrIf68qNO9wF8CWVXS0bJzEycbBAMvGOvY9gUKLDPr3Z9gnJV
vjcbMvnbv1wMnq3Qj1yJnNsz8u5/hNT4uQCpqrYhU1/2Y0CHF9JWB2XAiS1F25e3
7P13IFxG9/ZW7nAjsds1UcYoqrLYiiSVSEAqORj8CuYo1UXyzdN/eeQRlBTdZEdU
FJzwDa1o2peR8LgoF20gdHGML1v7SC1RRslgp1iRwoGQKuoERtTDNY898OdTbPkv
he8/jfbhW/Pw8HeGhf+zmXEW5vCBJxRzjLAwblasPcDsopDNFyujfOfgC0XHuJ9+
J7NC1vRS/CFK59CDp3E/xjyCp1c/Sa1OrWAkOxbmUaBnfJJpXy+Rj6kZOPC+SV1u
TFliXlnYD1KqRGUHz1YWq8lvDFSgfoLMSX6cDqZjFNvlxSPtJWUTCaWeU9yCaGL3
iV+eC5nkuQINBFvs3IwBEACctLEFM6igXHjRqDql3+ohl3UNMP/gFwtKtuPzq7uB
71v9rQjWhZPCffeCzWsl2W3bT0wfi+cXXubOAupiIBb7RXf3dFUQ7qLEODUfvUYo
+aNeW+M0ALO53ejj1UigXqDVrVey6Vb7yafSg3QxhtE3andlS2LEC6FY7SIHAwRQ
N/1Z/BosyWUacMZuMEOO1NsiT1O0J0/zCnqDPssQk+ivCfTKCEo4+e/cfUyWHVXf
0YdUadLak5P8205pAL7uoWGZ6H99eRm95gV5SPly8+WtKH5o5wq/PKrW3fRdYp1E
nIxdJa2fMP3tWTfJrA/7bdB5Fs+8PN4L4Wx5kt2+mprQ9FUksJW4OyxhIzudg/hP
07/Q2gbQXRIrdqoXuXEV7cSJofEV88d1V/Zv23O+zC2QLzhBiULEFKkYwSj7jJHe
7ISGZ3tAGwBeeKMj9+wH0P1zj0dRAy/87jKB7GWLgX9AiYzKgtsz5IybL37ZVoL9
Qf0x9N6n6pAR7DUEGtFbYQ6OPdcfAu+G/wHy4ST66ta9IeIAzQPR74/bjHOYeinO
FeQ/Cb35pd0a4gfCBvT+X0VPjvggZFAX8NhOv6r8/aZ5zsRIJZThjZX5xS2iwDe3
L2pvQsdIGnGkY1OESaRUUPKgy8ftJnQ69X8xMenqYUIgWt1NoFrD0ALM37DGNaqI
PQARAQABiQI2BBgBCAAgFiEEhHfua88eyv09h/ebSthgWpiKbv0FAlvs3IwCGwwA
CgkQSthgWpiKbv212Q/9EWnRkvxsJkb9vin1tHlFKbgy1at5VsL7bwWa3B0jTob8
CPuHQp3IZje/YxFlulWg+Z4Ub9dC6pARTi8VLgaD5D50df0rv72CLnHTM+QAeP7n
Q2FIZ0J+MbGnNeriECI1kEEDsOY2NLq06P/wwRZ3O4xRXyvUNrsUIfKECWHYS4sq
US4BXcs9Okd2GOT1VqjW+7anONiYPzGHeTxJJOSKKJ3vI6crhddZY2hWHfk9krbK
RJCn9BLKubrKK5ToEfuhQmsznFjgByLLREkJ92i1xIgn7Qr4C8/S1HYIq6pjAVkb
BDnyV9xN1zuF4Tdh+jvHL68DQZ50tgVl07jWgKYl5QwyBirA8X0eDSRLVC0ZlBuS
kF97DrEsYE+wQ+jZEEphs8rKbt/8sYKU2ZDga1TXxdp4vZHU5fJ6suWIfpFU++lv
JyrRVLa9ldtxxQtZf3G60sDtglwFX/09DFagSxq8LYHCmBatEhxqitRJtQhQM+cJ
qQWNWyWp74DOZ2pNEFagTq+Rha3YobIQzvPUKDESHhcKOybKcYcuDDxh7IA4npZl
P+cHDcJug8Wfv1LvR9VkS9QqBMdPqxQUMLQRhod0QtgiKY3gSJiDZYs/3s8FG8Kk
opLTDplv+YxxsxNmzuSUSl6To6qvxxvEay2yi2k6iD4VkequqUyVyJf/jfHtaj8=
=CBB2
-----END PGP PUBLIC KEY BLOCK-----`
