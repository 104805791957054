import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, CardTitle, Row, Col, Alert
} from 'reactstrap';

import TableComponent from '../../components/Table/Table.js';
import caspianApiService from '../../services/CaspianApiService';

import utils from '../../utils';
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";
import Loadable from "react-loading-overlay";
import {Redirect} from "react-router-dom";
import {Collapse} from "react-bootstrap";

const resourceFormatter = (cell) => {
    // let activeClass = 'active'
    return (
        <div className="td-editable-content">
            <span>{cell}</span>
        </div>
    )
}

const columnsConfig = [
  {
    label: '',
    size: {
      sm: 10
    },
    customColumnClass: '',
    matchDataField: 'fullName',
    formatter: resourceFormatter
  }
];

const DashboardCard = ({
  title,
  cardData,
 }) => {
  return (
    <Card className="card-stats card-raised resources-card">
        <CardHeader>
            <CardTitle style={{ fontSize: '12px' }}>{title}</CardTitle>
        </CardHeader>
        <CardBody>
            {/*this is for data that does not have multilevel inside a card (without UAT/PROD different resources) */}
          {
            (cardData.children) ?
                <TableComponent
                  rowsData={cardData.children}
                  columnsConfig={columnsConfig}
                  expandableRows={true}
                  customTableWrapperClass='dashboard-table1'
                  customTitleClass='title red'
                  customHeaderColumnClass='header-column'
                  customHeaderRowClass='header-row highlighted red'
                  customBodyColumnClass='body-column'
                  customBodyRowClass='resources-body-row red downloadable-resource'
                />
              : ''
          }

            {/*this is for data that does have multilevel inside a card (with UAT/PROD different resources) */}
          {
            Object.keys(cardData).map(function(key, index) {
              if(!cardData[key] || key === 'children') {
                return null;
              }
              return (
                <Row key={index}>
                  <Col>
                    <h5 style={{ fontSize: '12px'}}>{key}</h5>
                    <TableComponent
                          rowsData={cardData[key].children}
                          columnsConfig={columnsConfig}
                          expandableRows={true}
                          customTableWrapperClass='dashboard-table1'
                          customTitleClass='title red'
                          customHeaderColumnClass='header-column'
                          customHeaderRowClass='header-row highlighted red'
                          customBodyColumnClass='body-column'
                          customBodyRowClass='resources-body-row red downloadable-resource'
                        />
                    </Col>
                </Row>
              )
            })
          }
        </CardBody>
    </Card>
  );
};

const EmptyDashboardCard = ({
  title,
}) => {
  return (
    <Card className="card-stats card-raised resources-card">
        <CardHeader>
            <CardTitle style={{ fontSize: '12px' }}>{title}</CardTitle>
        </CardHeader>
        <CardBody/>
    </Card>
  )
};

class ClientCard extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: true }
  }

  handleClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  closeAllPopups = () => {
      let openedPopup = document.getElementsByClassName('versions-popup');
      if (openedPopup && openedPopup.length > 0) {
          for (let i=0; i<openedPopup.length; i++){
              if (openedPopup[i].style.display === 'block'){
                  openedPopup[i].previousElementSibling.style.color = '#bdbdbd';
                  openedPopup[i].style.display = 'none';
              }
          }
      }
  };

  render() {
    const {client} = this.props;
    const content = [];

    if(this.state.expanded) {
        if (Object.keys(client).length === 1 && client.children && client.children[0].name === '') {
            content.push(
                <EmptyDashboardCard
                    title = 'No resources for this client' />
            )
      }



      Object.keys(client).map(function(key, index) {
        if(!client[key] || key === 'children') {
          return null;
        }
        return content.push(
          <DashboardCard
            key={index}
            title = {key}
            cardData = {client[key]} />
        )
      })
    }

    return(
      <div className="client-card" onClick={this.closeAllPopups}>
        {content}
      </div>
    )
  }
}

class DashboardResources extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
          user: utils.getUserData(),
        resourcesFiles: [],
        loading: false,
        error: '',
        selectedClient: '',
        loadingData: false,
        loadingText: 'Loading resources..',
        isOpenCollapse: false,
      };
    }

    loadDataForUser = (clientName) =>{
        this.setState({
            selectedClient: clientName,
            visible: true
        }, () => {
            if (utils.isUserAccountManager()) {
                if (this.state.user && Array.isArray(this.state.user.clientsNames)) {

                    this.setState({isMultipleClientsAccountManager: true})
                } else {
                    this.setState({isMultipleClientsAccountManager: false})
                }
            }
            this.loadDataForAdmin(clientName)
        })
    }


    componentDidMount() {
        let clientName = JSON.parse(localStorage.getItem('activeClientName'))
        if (!clientName){
            caspianApiService.getUserDetails().then((user) => {
                localStorage.setItem('activeClientName', JSON.stringify(user.clientsNames[0]));
                clientName = user.clientsNames[0];
                this.loadDataForUser(clientName);
            });
        }
        else{
            this.loadDataForUser(clientName);
        }
    }

    loadResourcesForUser() {
        this.setState({
            loadingData: true,
            resourcesFiles: [],
            error: ''
        });
        caspianApiService.getResourceFiles()
            .then((files) => {

                const unorderedFiles = files;
                files = {};

                Object.keys(unorderedFiles).sort().forEach(function (key) {
                    files[key] = unorderedFiles[key];
                });

                if (files && Object.keys(files).length === 1 && files[Object.keys(files)[0]].children && files[Object.keys(files)[0]].children[0].name === '') {
                    this.setState({error: 'No resources available at the moment.'});
                } else {
                    this.setState({resourcesFiles: files});
                }
            }).then(() => {this.setState({loadingData: false})})
            .catch((err) => {
                this.setState({loadingData: false});
                if(err.errorStatus === 404){
                  this.setState({error: 'No resources available at the moment.'})
                } else {
                  this.setState({error: err.errorMessage})
                }
                if(!utils.userIsAuthenticated() || (!utils.isOnboardingCompleted() && !utils.isApplicationApproved())) {
                  window.location.reload();
                }
            })
    }

    loadDataForAdmin(clientName) {

        if( utils.isUserAdmin() || (utils.isApplicationApproved() || utils.isOnboardingCompleted())){
            this.setState({
                loadingData: true,
                resourcesFiles: [],
                error: ''
            });
            caspianApiService.getResourcesFileForClient(clientName)
                .then((files) => {
                    const unorderedFiles = files;
                    files = {};

                    Object.keys(unorderedFiles).sort().forEach(function (key) {
                        files[key] = unorderedFiles[key];
                    });

                    if (files && Object.keys(files).length === 1 && files[Object.keys(files)[0]].children && files[Object.keys(files)[0]].children[0].name === '') {
                        this.setState({error: 'No resources available at the moment.'});
                    } else {
                        this.setState({resourcesFiles: files});
                    }
                })
                .then(() => {this.setState({loadingData: false})})
                .catch((err) => {
                    this.setState({loadingData: false});
                    if(err.errorStatus === 404){
                        this.setState({error: 'No resources available at the moment.'})
                    } else {
                        this.setState({error: err.errorMessage})
                    }
                    if(!utils.userIsAuthenticated()) {
                        window.location.reload();
                    }
                })
        } else {
            this.setState({resourcesFiles: []})
        }


    }

    onClientChange = () => {
        let clientName = '';
        if (utils.isUserAdmin()) {
            clientName = JSON.parse(localStorage.getItem('activeClientName'));
        } else {
            clientName = JSON.parse(localStorage.getItem('activeClientName'));
        }
        this.setState({
            selectedClient: clientName,
            visible: true
        }, () => {
            this.loadDataForAdmin(clientName);
        })
    };

    renderError() {
      if(this.state.error) {
        return (
          <Alert color="warning">
            {this.state.error}
          </Alert>
        );
      }
    }

    toggle = () => {
        this.setState(prevState => ({ isOpenCollapse: !prevState.isOpenCollapse }));
    };

    renderCard() {
      const resourceFiles = this.state.resourcesFiles;
        return Object.keys(resourceFiles).map(function(key, index) {

        return <ClientCard
                  key={index}
                  clientName={key}
                  client={resourceFiles[key]} />
      })
    }

    render(){
        let containerWidth = '';
        if (!utils.isUserAdmin()) {
            containerWidth = '100%'
        }


        if (!utils.isUserAdmin()) {

            if(!utils.isOnboardingCompleted() || !utils.isApplicationApproved()){



                if (utils.isUserAccountManager() && (utils.isAwaitingKYC() || utils.isKYCPending() )) {
                    return (
                        <Redirect to={'/kyc'}/>
                    )
                }

                if (utils.isUserAccountManager() && (utils.isKYCCompleted() || utils.isSubmittedInitialData())) {
                    return (
                        <Redirect to={'/client-info'}/>
                    )
                }
            }
        }



        return (
            <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
                <div className="dashboard-resources-page">
                {
                    utils.isUserAdmin() &&
                    <ClientsSidebar onClientChange={this.onClientChange}/>
                }

                {
                    this.state.isMultipleClientsAccountManager && this.state.isMultipleClientsAccountManager === true ?
                        <ClientsSidebar isMultipleClientsAccountManager={true} user={this.state.user} onClientChange={this.onClientChange}/>
                        :
                        ''
                }
                    <div id={'content'} className={'content'} style={{padding: '0 14% 30px', 'minHeight': 'calc(100vh - 140px)', width: containerWidth}}>
                        <h3 className="title">Resources</h3>

                        <Row>
                            <Col xs={12} md={12}>
                                <div style={{padding: '15 15 15'}} className="client-card">
                                    <button className="collapsible" onClick={() => this.toggle()}>
                                        <span>How to Install</span>
                                    </button>
                                    <div className="card resources-card install-info">
                                        <Collapse  in={this.state.isOpenCollapse}>
                                            <div style={{paddingTop: '10px'}}>
                                                <ol>
                                                    <li>Click on the appropriate link for your system to download the installer file.<br/>PROD = Production, UAT = Simulated version</li>
                                                    <li>Navigate to the downloaded installer file and double-click to launch the install wizard.</li>
                                                    <li>If the Security Warning dialog is displayed, click <b>Run</b> to proceed.</li>
                                                    <li>Click through the License Agreement window by selecting “I accept the agreement” and clicking <b>Next</b>.</li>
                                                    <li>On the Bootstrapper screen, click the “Continue with LSEG TORA” button and enter your LSEG TORA Single Sign On credentials.</li>
                                                    <li>The application login screen is displayed with a version selection drop-down. Select the desired version and log in by pressing the “Continue with LSEG TORA” button.</li>
                                                    <li>Populate the Login Options screen as follows:
                                                        <ul>
                                                            <li>Role: Select Trader unless informed otherwise by your client services representative.</li>
                                                            <li>Choose language: Set display language as English or Japanese.</li>
                                                            <li>Connection settings: Set primary host to Internet for primary and backup DC. For
                                                                additional network settings, please refer to the Caspian System and Network
                                                                Requirements guide.</li>
                                                            <li>Logging folder: To specify a custom destination folder for log files, activate the
                                                                field by clicking in the check box and browse to the folder.</li>
                                                        </ul>
                                                    </li>
                                                </ol>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={12}>
                              {this.renderError()}
                              {this.renderCard()}
                            </Col>
                        </Row>
                    </div>
                    <div style={{clear: 'both'}}> </div>
                </div>
            </Loadable>
        );
    }
}

export default DashboardResources;
